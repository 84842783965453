<template>
  <layout>
    <template slot="main">
      <v-card>
        <crud
          :modal="tetikle"
          :gelen_id="gelen_id"
          @hideDialog="showClose()"
        ></crud>
        <v-card-title>
          Firmalar
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              tetikle = true;
              gelen_id = null;
            "
          >
            Yeni Firma
          </v-btn>
        </v-card-title>
        <datatables :headers="headers" :items="firmalar">
          <template v-slot:dt_actions="{ item }">
            <v-btn color="success" dark small @click="updateDialog(item.id)">
              Güncelle
            </v-btn>
            <v-btn class="ml-2" color="red" dark small @click="silDialog(item)">
              Pasif Et
            </v-btn>
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Crud from "../Firmalar/Crud.vue";
import Datatables from "../../components/datatables/index.vue";
import { firmalarListesi, firmaSil } from "../../query/firmalar";
import Layout from "@/components/Layout";
export default {
  components: { Crud, Datatables, Layout },

  async mounted() {
    this.firmalar = await firmalarListesi();
  },

  methods: {
    showClose() {
      this.tetikle = false;
    },
    async silDialog(item) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        const sonuc = await firmaSil(item.id);
        if (sonuc !== undefined) {
          this.firmalar = await firmalarListesi();
        }
      }
    },
    updateDialog(item) {
      console.log(item);
      this.gelen_id = item;
      this.tetikle = true;
    },
    hareketleriGoster(id) {
      this.$router.push({
        name: "CariHesapBilgisi",
        params: {
          id: id,
        },
      });
    },
  },

  watch: {
    async tetikle(val) {
      if (val === false) {
        this.firmalar = await firmalarListesi();
      }
    },
  },

  data() {
    return {
      firmalar: [],
      tetikle: false,
      gelen_id: null,
      options: {},
      headers: [
        {
          text: "Firma",
          value: "unvan",
          filterType: "string",
        },
        {
          text: "Yetkili",
          value: "adsoyad",
          filterType: "string",
        },
        {
          text: "Gsm",
          value: "gsm",
          filterType: "string",
        },
        {
          text: "İŞLEMLER",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
